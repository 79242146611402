<template>
  <div class="home">
    <left-sidebar />
    <div class="card new main">
      <div class="card-body" v-if="eventInfo.id">
        <h3 class="title">{{ eventInfo.title }}</h3>
        <h5 class="dates">
          <img src="@/assets/calendar.png" alt="" />
          <span
            :class="[
              'ml-2 text-white',
              type == 'upcoming'
                ? 'bg-primary'
                : type == 'ongoing'
                ? 'bg-success'
                : 'bg-warning',
            ]"
          >
            {{ parseDateTime(eventInfo.start_at) }}
          </span>
          <span class="px-3">-</span>
          <span
            :class="[
              'ml-2 text-white',
              type == 'upcoming'
                ? 'bg-primary'
                : type == 'ongoing'
                ? 'bg-success'
                : 'bg-warning',
            ]"
          >
            {{ parseDateTime(eventInfo.end_at) }}
          </span>
        </h5>
        <h5 class="description mb-3 mx-0">{{ eventInfo.description }}</h5>
        <img
          v-if="eventInfo.thumbnail"
          :src="eventInfo.thumbnail"
          class="post-image mb-2"
        />
        <div class="content pl-1" v-html="eventInfo.content" />
        <event-register v-if="type != 'finished'" class="mt-5" />
        <div class="bottom actions-row justify-content-between">
          <a
            @click.prevent="$router.push('/company/' + autor.id)"
            :href="'/company/' + autor.id"
            class="author d-flex align-items-center text-dark"
          >
            <div
              class="profile-pic"
              :style="
                autor.avatar
                  ? { 'background-image': `url(${autor.avatar})` }
                  : {}
              "
            />
            <p class="author-name">{{ autor.name }}</p>
            <b-icon-check-circle-fill class="ml-2" variant="primary" />
          </a>
          <div class="share" @click.prevent="share()">
            <img src="@/assets/share.svg" alt="" />
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center">
        <b-spinner class="m-4" variant="success"></b-spinner>
      </div>
    </div>
    <right-sidebar />
  </div>
</template>

<script>
import LeftSidebar from "../../components/LeftSidebar.vue";
import RightSidebar from "../../components/RightSidebar.vue";
import EventRegister from "../../components/EventRegister.vue";

export default {
  components: { LeftSidebar, RightSidebar, EventRegister },
  metaInfo() {
    return {
      title: this.eventInfo.title,
      meta: [
        { name: "description", content: this.eventInfo.description },
        { property: "og:title", content: this.eventInfo.title },
        { property: "og:site_name", content: "techvibe" },
        { property: "og:image", content: this.eventInfo.thumbnail },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      eventInfo: {},
    };
  },
  async mounted() {
    try {
      const resp = await this.axios.get(
        "public/events/" + this.$route.params.id
      );
      this.eventInfo = resp.data.data;
    } catch (ignored) {
      this.$router.push("/events");
    }
  },
  methods: {
    parseDateTime(newDate) {
      const dateTime = new Date(String(newDate).replace(" ", "T"));
      let date = dateTime.getDate();
      date = date / 10 > 1 ? date : `0${date}`;
      let month = dateTime.getMonth() + 1;
      month = month / 10 > 1 ? month : `0${month}`;
      const year = dateTime.getFullYear();
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      return `${date}-${month}-${year}  ${hours}:${
        minutes >= 10 ? minutes : `0${minutes}`
      }`;
    },
    share() {
      this.shareUrl("/events/" + this.eventInfo.id, this.eventInfo.title);
    },
  },
  computed: {
    type() {
      if (
        new Date(String(this.eventInfo.start_at).replace(" ", "T")).getTime() >
        Date.now()
      )
        return "upcoming";
      else if (
        new Date(String(this.eventInfo.end_at).replace(" ", "T")).getTime() >
        Date.now()
      )
        return "ongoing";
      else return "finished";
    },
    autor() {
      if (this) {
        this.$forceUpdate();
        return this.eventInfo.company;
      } else return {};
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer !important;
}
.title {
  font-weight: 700;
  color: black !important;
}
.card {
  font-family: Roboto !important;
  margin-top: 20px;
  border-radius: 15px;
  border: none;
  margin-bottom: 100px;
  width: 790px;
}
.dates {
  margin: 10px 0;
  display: flex;
  align-items: center;

  span {
    padding: 5px 10px;
    border-radius: 5px;
  }
}
.top,
.bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .verified {
    margin-left: 10px;
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 50%;
    background-color: #4e92f1;
    font-weight: bold;
    color: white;
    height: max-content;
  }
  .author {
    padding: 0 10px 0 0;
  }
  .author:hover {
    cursor: pointer;
    border-radius: 20px;
    text-decoration: underline;
    background-color: rgba(128, 128, 128, 0.082);
  }
  p {
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 0;
  }
  .time {
    margin-left: 3%;
    font-size: 14px;
    opacity: 0.4;
  }
}
.bottom {
  margin-top: 10px;
  padding-top: 10px;
}
.thumbnail {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
.post-image {
  border-radius: 10px;
  width: 100%;
}
.description {
  max-width: 100%;
  word-wrap: break-word;
  margin: 10px 5px;
  margin-bottom: 20px;
}
.actions-row {
  display: flex;
  .mutted {
    opacity: 0.5;
  }
  .share {
    img {
      height: 23px;
    }
  }
  span {
    font-weight: bold;
    margin-left: 4px;
    color: var(--itpark);
  }
}
.actions-row > * {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}
.actions-row > *:hover {
  background-color: rgba(128, 128, 128, 0.082);
}
@media (max-width: 767px) {
  .card {
    width: 100%;
    border-radius: 0 !important;
    margin-top: 2px !important;

    .card-body h3 {
      font-size: 22px !important;
    }
  }
  h4 {
    font-size: 20px !important;
  }
}
@media (max-width: 540px) {
  .dates {
    font-size: 14px;
  }
  .bottom {
    .profile-pic {
      min-width: 30px;
      max-width: 30px;
      height: 30px;
    }
    .verified {
      transform: scale(0.7);
      opacity: 0.8;
    }
  }
  .thumbnail {
    display: block;
    margin-left: -16px !important;
    margin-right: -16px !important;
    border-radius: 0 !important;
  }
  .description {
    font-size: 16px;
  }
  button.main-outlined {
    padding: 5px 15px;
    font-size: 14px;
  }
  .actions-row > * {
    margin: 0px !important;
  }
  .share,
  .comments {
    img {
      height: 20px !important;
    }
  }
}
</style>