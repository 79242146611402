<template>
    <div>
        <b-modal ref="eventRegister" id="eventRegister" size="lg" hide-footer :title="t('signUp2Event')">
            <b-form @submit.stop.prevent="submit()">
                <b-form-group :label="t('fullName')" label-for="input-1">
                    <b-form-input
                    id="input-1"
                    v-model="form.fio"
                    type="text"
                    placeholder="John Doe"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group :label="t('phone')" label-for="input-2">
                    <b-form-input
                    id="input-2"
                    v-model="form.phone"
                    type="number"
                    placeholder="+998 99"
                    required
                    ></b-form-input>
                </b-form-group>
                <b-form-group :label="t('sex')" label-for="input-3">
                    <b-form-select
                    id="input-3"
                    v-model="form.sex"
                    :options="sexes"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group :label="t('age')" label-for="input-3">
                    <b-form-input
                    id="input-3"
                    v-model="form.age"
                    type="number"
                    placeholder="21"
                    required
                    ></b-form-input>
                </b-form-group>
                <b-form-group :label="t('yourEmail')" label-for="input-4">
                    <b-form-input
                    id="input-4"
                    v-model="form.email"
                    type="email"
                    placeholder="____@gmail.com"
                    required
                    ></b-form-input>
                </b-form-group>
                <b-form-group :label="t('city')" label-for="input-5">
                    <b-form-input
                    id="input-5"
                    v-model="form.location"
                    type="text"
                    placeholder=""
                    required
                    ></b-form-input>
                </b-form-group>
                <div class="d-flex">
                    <div class="round">
                        <input type="checkbox" id="checkbox" v-model="acceptAgreement" />
                        <label for="checkbox"></label>
                    </div>
                    <label class="remember" for="checkbox">
                        <router-link to="/policy">{{ t('doAcceptPolicy') }}</router-link>
                    </label>
                </div>
                <div class="d-flex justify-content-end p-1">
                    <button class="main" variant="primary">{{ t('send') }}</button>
                </div>
            </b-form>
        </b-modal>
        <div class="d-flex justify-content-center">
            <button v-if="!registered" class="main" v-b-modal.eventRegister>{{ t('signUp2Event') }}</button>
            <button v-else class="btn btn-secondary d-flex align-items-center"> <b-icon-check-circle class="mr-2" /> {{ t('AlreadySigned2Event') }}</button>
        </div>
    </div>
</template>

<script>
import cookies from 'vue-cookies'

export default {
    data() {
        return {
            sexes: [
                { 
                    text: this.t('man'),
                    value: 1,
                },
                { 
                    text: this.t('woman'),
                    value: 0,
                },
            ],
            acceptAgreement: true,
            registered: false,
            form: {
                fio: '',
                phone: '',
                age: '',
                email: '',
                sex: 1,
                location: '',
            }
        }
    },
    mounted() {
        const userInfo = this.$store?.state?.userInfo || null
        if(userInfo) {
            this.form.email = userInfo.email
            this.form.fio = userInfo.name
        }
        this.updateData()
    },
    methods: {
        async submit() {
            if(this.form.phone.length < 8) {
                this.error(this.t('phoneWrong'))
                return ;
            } else if(!this.acceptAgreement) {
                this.error(this.t('acceptPolicy'))
                return ;
            }
            const formData = new FormData()
            formData.append('fio', this.form.fio)
            formData.append('name', this.form.name)
            formData.append('email', this.form.email)
            formData.append('age', this.form.age)
            formData.append('phone', this.form.phone)
            formData.append('sex', this.form.sex)
            formData.append('location', this.form.location)

            try {
                await this.axios.post('pass/' + this.$route.params.id + '/register', formData)
                this.alert(this.t('youRegisteredEvent'))
                this.$bvModal.hide('eventRegister')
                let registeredEvents = cookies.get('registeredEvents') || null
                if(registeredEvents)
                    registeredEvents += ',' + String(this.$route.params.id)
                else
                    registeredEvents = String(this.$route.params.id)
                cookies.set('registeredEvents', registeredEvents, 364 * 24 * 3600)
                this.updateData()
            } catch(ignored) {
                this.error(this.t('errorWithEvent'))
            }
        },
        updateData() {
            const registeredEvents = JSON.parse('[' + cookies.get('registeredEvents') + ']')
            console.log(registeredEvents, Number(this.$route.params.id), registeredEvents.includes(Number(this.$route.params.id)))
            this.registered = registeredEvents.includes(Number(this.$route.params.id))
        }
    },
}
</script>

<style scoped>
    label {
        cursor: pointer;
    }
    .remember {
        font-size: 14px;
        text-align: left;
        margin-left: 20px;
    }
    .round {
        position: relative;
    }
    .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
        display: flex; 
        justify-content: center;
        align-items: center;
    }
    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 4px;
        opacity: 0;
        position: absolute;
        transform: rotate(-45deg);
        width: 8px;
    }
    .round input[type="checkbox"] {
        visibility: hidden; 
    }
    .round input[type="checkbox"]:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }
    .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }
</style>