var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('left-sidebar'),_c('div',{staticClass:"card new main"},[(_vm.eventInfo.id)?_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.eventInfo.title))]),_c('h5',{staticClass:"dates"},[_c('img',{attrs:{"src":require("@/assets/calendar.png"),"alt":""}}),_c('span',{class:[
            'ml-2 text-white',
            _vm.type == 'upcoming'
              ? 'bg-primary'
              : _vm.type == 'ongoing'
              ? 'bg-success'
              : 'bg-warning' ]},[_vm._v(" "+_vm._s(_vm.parseDateTime(_vm.eventInfo.start_at))+" ")]),_c('span',{staticClass:"px-3"},[_vm._v("-")]),_c('span',{class:[
            'ml-2 text-white',
            _vm.type == 'upcoming'
              ? 'bg-primary'
              : _vm.type == 'ongoing'
              ? 'bg-success'
              : 'bg-warning' ]},[_vm._v(" "+_vm._s(_vm.parseDateTime(_vm.eventInfo.end_at))+" ")])]),_c('h5',{staticClass:"description mb-3 mx-0"},[_vm._v(_vm._s(_vm.eventInfo.description))]),(_vm.eventInfo.thumbnail)?_c('img',{staticClass:"post-image mb-2",attrs:{"src":_vm.eventInfo.thumbnail}}):_vm._e(),_c('div',{staticClass:"content pl-1",domProps:{"innerHTML":_vm._s(_vm.eventInfo.content)}}),(_vm.type != 'finished')?_c('event-register',{staticClass:"mt-5"}):_vm._e(),_c('div',{staticClass:"bottom actions-row justify-content-between"},[_c('a',{staticClass:"author d-flex align-items-center text-dark",attrs:{"href":'/company/' + _vm.autor.id},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/company/' + _vm.autor.id)}}},[_c('div',{staticClass:"profile-pic",style:(_vm.autor.avatar
                ? { 'background-image': ("url(" + (_vm.autor.avatar) + ")") }
                : {})}),_c('p',{staticClass:"author-name"},[_vm._v(_vm._s(_vm.autor.name))]),_c('b-icon-check-circle-fill',{staticClass:"ml-2",attrs:{"variant":"primary"}})],1),_c('div',{staticClass:"share",on:{"click":function($event){$event.preventDefault();return _vm.share()}}},[_c('img',{attrs:{"src":require("@/assets/share.svg"),"alt":""}})])])],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{staticClass:"m-4",attrs:{"variant":"success"}})],1)]),_c('right-sidebar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }